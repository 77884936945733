.myOrdersList__container .myOrder__card {
  width: 100%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.27);
  margin: 10px 0px;
}

.myOrder__card .div__detail-item-actions div{
  font-size: clamp(1rem,1.5vw,1.5rem);
}

.myOrder__card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.myOrder__card-header p {
  font-weight: normal;
  font-size: clamp(0.9rem, 1vw, 2rem);
}

.myOrder__card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myOrder__card-content-image {
  width: 190px;
  height: 155px;
  border-radius: 8px;
}

.myOrder__card-content {
  display: flex;
  width: 100%;
}

.myOrder__card-content-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  color: white;
}

.myOrder__card-content-container {
  display: flex;
  width: 98%;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.myOrder__card-content-image {
  height: 155px;
  width: 190px;
  border-radius: 20px;
}

@media (max-width : 729px){
  .myOrder__card-content-image {
    height: 98px;
    width: 88px;
    border-radius: 10px;
  }

}

.myOrder__card-content-details-title {
  font-size: clamp(1rem, 1.2vh,2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.myOrder__card-content-details-orderId {
  color: #b1b1b1;
}

.myOrder__card-content-details .myOrder__card-content-details-invoice {
  color: #aaff32;
  text-transform: none;
  font-size: 20px;
  margin-left: 10px;
  text-decoration: none;
}

.myOrder__card-content-details .myOrder__card-content-details-invoice .MuiSvgIcon-root {
  vertical-align: middle;
}

.myOrder__card-content-timeline-label .MuiStepLabel-label,
.myOrder__card-content-timeline-label .MuiStepLabel-label.Mui-completed {
  font-size: clamp(1rem,1.2vh,2rem);
  color: white;
}

.myOrder__card-content-timeline .MuiStepConnector-line {
  border: 2px solid grey;
  margin: 10px;
  border-radius: 5px;
}

.myOrder__card-content-timeline .Mui-completed .MuiStepConnector-line {
  border: 2px solid #85c340;
}

.myOrder__card-content-timeline .myOrder__card-content-timeline-icon {
  background-color: grey;
  color: white;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  padding: 10px;
}

.myOrder__card-content-timeline
  .Mui-completed
  .myOrder__card-content-timeline-icon {
  background-color: #85c340;
}

.myOrder__card-content-itemDetail {
  display: flex;
}

.myOrder__card-content-timeline .MuiStepper-root {
  margin: 10px 0px;
}

.myOrder__card-content-details > p {
  font-size: clamp(1rem, 2.2vh, 2rem);
}
.myOrder__card-content-timeline {
  display: flex;
  flex-direction: column;
}

@media (max-width: 944px){
  .myOrder__card-content-timeline{
    margin-top: 27px;
  }

}

