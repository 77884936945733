.div__noSearchResults {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 55%;
    margin: auto;
}

.imgNoSearchResults {
    aspect-ratio: 567/394;
    width: 60%;
    margin: 20px;
}

.lblSorry, .lblSorryMessage, .lblRecommendUs {
    text-align: center;
    margin: 0.3em 0px;
}

.lblSorry {
    font-size: clamp(1.5rem, 1.8vw,2rem);
}

.lblSorryMessage, .lblRecommendUs {
    font-size: clamp(1.1rem,1.4vw,2rem);
}

.div__memeSuggestionForm {
    margin: 50px 0px;
    width: 100%
}

#btnInput.btnSuggestMemes {
    width: 50%;
    height: 50px;
    font-size: clamp(1rem, 1.5vh, 1.5rem);
}

.MuiDrawer-root.addMemeSuggestionDrawer .MuiDrawer-paper {
    background-color: #585858;
}

.MuiDrawer-root.addMemeSuggestionDrawer .MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
    height: 83%;
}