.myTickets-overview {
  display: flex;
  margin: 10px 0px;
  justify-content: flex-start;
  flex-direction: column;
}

.myTickets-grid {
  flex: 0.65;
}

.myTickets .myTickets__ticketCard {
  margin: 10px 0px;
}

.myTickets__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myTickets__filters-selectCategory {
  padding: 4px;
  width: 226px;
  height: 56px;
  background-color: white;
  color: #686868;
  margin: 1px;
}

.myTickets__filters-selectCategory .MuiSelect-icon.MuiSelect-iconOutlined {
  color: black;
}

.myTickets__filters-searchInput {
  width: 985px;
  font-size: 20px;
}

.myTickets-emptyList {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
}

.myTickets-imgEmptyList {
  width: 80%;
  aspect-ratio: 543/289;
}

.myTickets-lblEmptyListMessage,
.myTickets-lblEmptyListSubMessage {
  margin: 20px 0px;
}

.myTickets-lblEmptyListMessage {
  font-size: 22px;
}

.myTickets-lblEmptyListSubMessage {
  font-size: 22px;
}

.myTickets-container .myTickets-btnAddTicket,
.myTickets-container .myTickets-btnAddTicket:hover {
  background: #86c232;
  color: white;
  align-self: center;
  font-size: 22px;
  height: 60px;
  width: 300px;
}

.myTickets-popupContent {
  text-align: center;
  padding: 10px;
}

.myTickets-popupContent-confirmQuestion {
  font-size: 25px;
}

.myTickets-popupContent-confirmMessage {
  font-size: 20px;
  margin: 25px;
  color: #a5a5a5;
}

.div__detail-item-actions div {
  font-size: clamp(1.1rem,2vh,2rem);
  cursor: pointer;
}

.MuiDrawer-root.myTickets-ticketDrawer--add .MuiDrawer-paper {
  background-color: #585858;
}

.MuiDrawer-root.myTickets-ticketDrawer--view .MuiDrawer-paper {
  background-color: white;
}

.MuiDrawer-root.myTickets-ticketDrawer--add .MuiDrawer-paper,
.MuiDrawer-root.myTickets-ticketDrawer--view .MuiDrawer-paper {
  height: 80%;
}

.MuiDrawer-root.myTickets-ticketDrawer--view .btnCloseDrawer svg {
  color: #707070;
}

.MuiDrawer-root.myTickets-ticketDrawer--add .btnCloseDrawer svg {
  color: white;
}

.myTickets .MuiGrid-root {
  flex-direction: column;
  margin: 10px 0px;
  width: 100%;
}

@media (max-width : 845px) {
    
  .myTickets-popupContent-confirmQuestion {
    font-size: 20px;
  }

  .myTickets-popupContent-confirmMessage {
    font-size: 18px;
  }

  .myTickets-popupContent-confirmMessage {
    margin: 12px;
  }

  .MuiDialogContent-root .myTickets-popupContent {
    padding: 0px;
  }

}