.order__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 75vh;
    margin: 50px auto;
    background-color: white;
    justify-content: space-around;
    border-radius: 10px;
}

.order__success-image {
    width: 300px;
    height: 360px;
}

.order__success-message {
    color: #222629;
    font-size: clamp(1rem, 1.5vw, 3rem);
    margin: 30px 0px;
    text-align: center;
    width: 70%;
}

.order__success-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
}

.order__success-actions .order__success-actions--viewOrder,
#btnInput.order__success-actions--continueShopping {
    width: 260px;
    height: 55px;
    border-radius: 5px;
    box-shadow: 2px 3px 9px 0 rgba(0, 0, 0, 0.16);
    text-transform: capitalize;
    font-size: 24px;
    margin: 0px;
}

.order__success .order__success-actions .order__success-actions--viewOrder {
    border: solid 1px #85c340;
    color: #85c340;
}