
.mobile .vendorWalletDashboard__gridContainer{
    flex-direction: column;
    width: 100%;
    
}
.mobile .vendorWalletDashboard__header .vendorWalletDashboard__header--left-icon {
    font-size: 32px;
}

.mobile .vendorWalletDashboard__gridContainer-monthlySummary{
    width: 90%;
}

.mobile .div__detail-item.vendorDashboard__gridContainer--bankAccountsList,
.mobile .div__detail-item.walletBalance__card
{
    width: 100%;
}

.mobile .vendorWalletDashboard__monthlySummary{
    width: 100%;
}

.mobile .vendorWalletDashboard__header--right{
    width: 35%;
}

.mobile .vendorWalletDashboard__header .vendorWalletDashboard__header--right-addBankAccount-button, 
.mobile .vendorWalletDashboard__header .vendorWalletDashboard__header--right-addBankAccount-button:hover{
    font-size: clamp(0.8rem,1.2vw,1.5rem);
    height: 55px;
}

.mobile .vendorWalletDashboard .MuiGrid-root .MuiGrid-item{
    width: 100%;
    max-width: none;
}

.mobile .vendorWalletDashboard__monthlySummary-content{
    margin-left: 20px;
}

.mobile .verticalDivider {
    margin: 0px 6px;
}

.mobile .vendorWalletDashboard{
    padding: 10px;
    align-items: center;
}

.mobile .vendorWalletDashboard__header--left {
    margin: 20px 0px;
}

.mobile .vendorWalletDashboard__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.mobile .vendorWalletDashboard__header--right{
    width: 55%;
    display: flex;
    flex-direction: column;
}
