.tabletLandscape .admin__vendorsList__filters,
.tabletPortrait .admin__vendorsList__filters {
    flex-direction: column;
}

.tabletLandscape .div__searchInput{
    width: 100%;
}

.tabletLandscape .div_Filters {
    width: 101%;
    margin: 10px 0px;
}

.tabletPortrait .div_Filters {
    width: 102%;
    margin: 10px 0px;
}
@media (max-width : 1024px) {
   .div__searchInput {
       width: 100%;
   }
   .div_Filters{
       width: 100%;
   }
} 