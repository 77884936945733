.tabletLandscape .myOrders__container {
  padding: 4% 4%;
}

.tabletPortrait .myOrder__card-content-container {
  flex-wrap: nowrap;
}

.tabletPortrait .myOrders__container {
  padding: 4% 4%;
}

.tabletPortrait .myOrders-imgEmptyList,
.tabletLandscape .myOrders-imgEmptyList {
  width: 357px;
  height: 267px;
}

.tabletLandscape .div__detail-item, .tabletPortrait .div__detail-item{
  margin: 20px 0px;
}