.AdminchatHeader__Container{
    height: auto;
    background: #86c232;
    width: 350px;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdminChat__header{
    color: white;
    font-size: clamp(0.8rem,1.5vw,1.5rem);
}

.AdminChat__subheader{
    color: white;
    font-size: clamp(0.6rem,1vw,1.2rem);
    margin: 5px 0px;
}


.AdminChatHeader__Container .MuiButtonBase-root .MuiSvgIcon-root{
    font-size: clamp(0.8rem,1.8vw,1.8rem);
    color: white;
}

.AdminchatHeader__Container .icon__closeChat,
.AdminchatHeader__Container .icon__closeChat:hover {
    display: none;
}