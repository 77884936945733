.div__addProductForm {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.div__addProductForm-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.div__addProductForm-container-header {
    display: flex;
    justify-content: space-between ;
    width: 100%;
    margin-top: 25px;
}

.div__addProductForm .iconCancelProduct {
    width: 40px;
    align-self: flex-end;
    margin-right: 40px;
}

.iconCancelProduct svg {
    color: white;
    font-size: clamp(1.5rem, 2vw, 2rem);
}

.headerAddProduct {
    font-size: clamp(1.5rem, 2vw, 2rem);
    align-self: center;
    margin-left: 45%;
}

.div__addProductForm-primary-dropdown .div__txtInput,
.div__addProductForm-primary-dropdown .div__autoComplete-input {
    flex: 1;
    padding: 0px 2.5px;
}

.div__addProductForm .div__txtInputArea .lblInput,
.div__addProductForm .div__dropdown-input .lblInput,
.div__addProductForm .div__autoComplete-input .lblInput {
    margin: 5px 0px;
}

.div__addProductForm-primary-dropdown .div__dropdown-input {
    margin-left: 10px;
}

.div__addProductForm .div__dropdown-input {
    width: 100%;
}

.div__addProductForm-secondary-dropdown .div__dropdown-input {
    padding: 0px;
}

.div__addProductForm-secondary .btnCancelProduct,
.div__addProductForm-actions .btnCancelProduct,
.div__addProductForm-secondary .btnCancelProduct:hover,
.div__addProductForm-actions .btnCancelProduct:hover {
    align-self: flex-end;
    width: 250px;
    margin: 10px 20px;
    padding: 5px 25px;
    background-color: transparent;
    text-transform: none;
    color: #86c232;
    font-size: 15px;
    border: 2px solid #86c232;
}

.div__addProductForm-primary {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    width: 45%;
}

.div__addProductForm-secondary {
    width: 55%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}

.div__addProductForm-primary-dropdown, .div__addProductForm-secondary-dropdown {
    display: flex;
    justify-content: space-between;
}

.div__addProductForm-secondary-dropdown {
    margin-bottom: 10px;
}

.div__addProductForm-imageUpload {
    display: flex;
    margin: 10px 5px;
    justify-content: center;
}

.div__addProductForm-primary-finance {
    display: flex;
}

.div__addProductForm-primary-dropdown .selectLanguage,
.div__addProductForm-secondary-dropdown .selectFabricType,
.div__addProductForm-secondary-dropdown .selectWashType {
    padding: 0px;
    background-color: white;
    color: black;
    height: 40px;
    margin: 0px 3px;
}

.div__addProductForm-primary-dropdown .autoCompleteLanguage {
    padding: 0px;
    background-color: white;
    color: black;
    height: 40px;
    border-radius: 8px;
}

.selectFabricType .MuiSelect-icon.MuiSelect-iconOutlined,
.selectLanguage .MuiSelect-icon.MuiSelect-iconOutlined,
.selectWashType .MuiSelect-icon.MuiSelect-iconOutlined { 
    color: black;
}

.div__addProductForm-imageUpload {
    display: flex;
    overflow: auto;
}

.div__addProductForm-videoEmbed {
    display: flex;
}

.div__embedVideoPreview {
    background: white;
    border-radius: 10px;
    display: flex;
    margin: 0px auto;
    width: 100%;
    aspect-ratio: 1920/1080;
    align-self: center;
    flex-direction: column;
    margin-top: 25px;
}

.div__embedVideoPreview .playPreviewIcon {
    color: #b8ff53;
    font-size: 60px;
    margin: auto;
}

.div__embedVideoPreview .div__embedVideoPreview_video {
    height: 100%;
}

.div__addProductForm-secondary button.btnPreviewVideo {
    align-self: center;
}

.div__addProductForm-secondary-videoPreview {
    display: flex;
    justify-content: space-around;
}

.lblVideoPreview {
    align-self: center;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
}

.div__addProductForm-inventoryList-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.div__addProductForm-videoEmbed {
    display: flex;
    align-items: center;
    margin: 25px;
}

.div__addProductForm-videoEmbed .div__txtVideoEmbed {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.loading {
    text-align: center;
    align-self: center;
    color: white;
}

.div__addProductForm-inventoryList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 30px;
    align-items: center;
    width: 97.5%;
}

.lblInventoryDetails {
    font-size: clamp(1.3rem, 2vw, 2rem);
    margin: auto 0px;
}

.div__addProductForm-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.div__addProductForm-actions button.btnAddProduct,
.div__addProductForm-inventoryList-header button.btnAddInventory,
.div__addProductForm-secondary button.btnPreviewVideo {
    background-color: #86c232;
    text-transform: none;
    color: white;
    font-size: 16px;
    width: 250px;
}

.MuiDrawer-root.addInventoryDrawer .MuiDrawer-paper {
    background-color: #585858;
}
