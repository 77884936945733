.savedPayments__list-method {
    display: flex;
    background: #222629;
    align-items: center;
    height: 60px;
    width: 50%;
    border-radius: 10px;
    justify-content: space-around;
}

.savedPayments__list-method-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.savedPayments__list .savedPayments__list-method-details .MuiRadio-root.MuiButtonBase-root .MuiSvgIcon-root,
.savedPayments__list .savedPayments__list-method-details .MuiRadio-root.MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
    color: white;
    font-size: clamp(1rem, 1vw, 2rem);
}

.savedPayments__container .savedPayments__list-method-cardCVV .div__txtInput span.errorMessage {
    color: crimson !important; 
    font-size: clamp(0.8rem,1vw,1.2rem) !important;
}