.div__landingPage-container {
  display: flex;
  flex-direction: column;
}

.div__landingPage-topLanguages,
.div__landingPage-latestAdditions {
  background-color: #212628;
  height: 70px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.imgBanner {
  width: 100%;
  aspect-ratio: 1920/577;
}

.div__landingPage-topLanguages,
.div__landingPage-latestAdditions {
  text-align: center;
  font-family: Helvetica;
  font-size: clamp(0.8rem, 2vw, 1.7rem);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 17.36px;
  display: grid;
  place-content: center;
  text-align: center;
  color: #fff;
  position: relative;
}

.mobile .div__landingPage-topLanguages p, .mobile .div__landingPage-latestAdditions p {
    font-size: 15px;
}

.div__landingPage-latestAdditions {
  margin: 5px 0px;
}

.div__landingPage-latestAdditions-list {
  margin: 10px auto;
  width: 85%;
  padding: 2px 10px;
}

.div__landingPage-container .div__landingPage-topLanguages-list {
  display: flex;
  padding: 0px 30px;
  justify-content: center;
}

.div__landingPage-topLanguages-list .MuiGrid-root {
    width: 20%;
}

.div__language-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: clamp(1rem,1.5vw,2rem);
  cursor: pointer;
  width: 100%;
  aspect-ratio: 325/356;
  background-repeat: no-repeat;
  background-size: cover;
}

.div__language-card p {
  margin-bottom: 18px;
  margin-left: 20px;
  letter-spacing: 5px;
}

.div__landingPage-container #btnInput.btnViewCollections {
  margin: 20px auto;
  font-size: clamp(1rem, 1vw, 1.2rem);
}

.div.imgOverlay {
  width: 100%;
  aspect-ratio: 325/356;
  object-fit: contain;
}

.mobile .div__landingPage-topLanguages-list .MuiGrid-root{
  width: 50%;
}

.mobile .div__landingPage-container .div__landingPage-topLanguages-list{
  padding: 10px;
}

#routesContainer .chat__Container {
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 33%);
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1500;
}

#routesContainer .div__landingPage-Chat__FAB,
#routesContainer .div__landingPage-Chat__FAB:hover {
  background-color: #74a730;
  position: fixed;
  bottom: 20px;
  right: 40px;
  justify-content: flex-end;
  z-index: 1500;
}

#routesContainer .div__landingPage-Chat__FAB .MuiSvgIcon-root {
  margin: auto;
  color: white;
}