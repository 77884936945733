.div__chatDetails-header,
.div__chatDetails-StartDate,
.div__chatDetails-OrderId,
.div__chatDetails-Vendor{
    margin: 10px 0px;
}

.AdminChat__container{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-around;
}

.div__Chat-Container{
    width: 50%;
    height: 100%;
}

.div__Chat-Container .AdminchatHeader__Container {
    height: auto;
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 0px;
    background: none;
    border: solid 1px grey;
    align-items: center;
}

.div__Chat-Container .chatList__Container{
    display: flex;
    padding: 0px;
    width: 100%;
    height: 80%;
    justify-content: center;
}

.AdminChat__container .chatList__Container {
    background: white;
    border: 1px solid #707070;
}

.div__chatDetails-Container{
    width: 22%;
}

.div__Chat-Container .ChatFooter__Container{
    width: 100%;
    padding: 1px;
}

.div__UserList__container{
    width: 28%;
    padding: 3px 1px;
}

.AdminChat__container .ReceiveCard
,.AdminChat__container .sendCard{
    margin: 15px;
}


.AdminChat__container .OrderPromptCard{
    padding: 0px;
    height: auto;
    margin:15px;
}

.AdminChat__container .OrderItemImage,
.AdminChat__container .OrderPromptCard__text{
    margin: 15px;
}

.AdminChat__container .AdminChatHeader__Container{
    margin: 13.5px;
}

.div__Chat-Container .ChatFooter__Container .div__txtInput{
    margin: 15px;
}