.chatList__Container {
    height: 50vh;
    background: white;
    width: 350px;
    padding: 10px 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.chatList__Container::-webkit-scrollbar {
    display: none;
}

.messageCard__Container{
    display: flex;
    flex-direction: column;
    width: 95%;
}
