/* .withdrawal__form-header--title,
.withdrawal__form-header--subTitle {
    color: #585858;
} */

.withdrawal__form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.withdrawal__form-container {
    display: flex;
    flex-direction: column;
}

.withdrawal__form-header--title {
    font-size: clamp(1.5rem, 2vw, 3rem);
    margin-bottom: 11px;
}

.withdrawal__form-verifyPIN-header {
    font-size: clamp(0.9rem, 1.2vw, 2rem);
}

.withdrawal__form-header--subTitle {
    font-size: 22px;
    margin-bottom: 22px;
}

.withdrawal__form .div__dropdown-input {
    width: 100%;
    padding: 0px;
    margin: 5px 0px;
}

.withdrawal__form .div__dropdown-input .MuiOutlinedInput-root {
    height: 45px;
}