.ReceiveCard__Container,
.SendCard__Container {
    display: flex;
    width: 70%;
    background-color: #ddd;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 15px;
    margin: 10px 0px;
    flex-direction: column;
    justify-content: space-between;
}

.SendCard__Container{
    align-self: flex-end;
}

.ReceiveCard__Container{
    border-radius: 0px 10px 10px 10px;
}

.AdminChat__container .SendCard__text {
    color: #808080;
}

.ReceiveCard__text,
.ReceiveCard__time,
.SendCard__time {
    color: #808080;
}

.ReceiveCard__text,
.SendCard__text {
    font-size: clamp(1rem,1.1vw,2rem);
}

.ReceiveCard__time,
.SendCard__time {
    font-size: clamp(0.8rem,1vw,1.2rem);
}

.SendCard__Container {
    height: auto;
    justify-content: flex-start;
    background-color: #5ea361;
    width: 70%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px 0px 10px 10px;
    padding: 5px 15px;
    margin: 5px 0px;
}

.AdminChat__container .SendCard__Container {
    background-color: #ddd;
}

.AdminChat__container .ReceiveCard__Container {
    background-color: #5ea361;
}

.AdminChat__container .ReceiveCard__text {
    color: white;
}

.SendCard__text {
    color: white;
}

.SendCard__time{
    align-self: flex-end;
}

.ReceiveCard__time{
    align-self: flex-start;
}

.messageCard__Container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.messageCard__Container::-webkit-scrollbar{
    width: 0px;
}