.div__register-container {
    display: flex;
    justify-content: center;
}

.div__register-logo {
    display: flex;
    flex: 1;
    align-items: center;
}

.link__home {
    margin: auto;
}

.img__registerLogo {
    width: 478px;
    height: 423px;
}

.div__register-formContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    margin: 30px 5%;
}

.div__register-registration {
    display: flex;
    flex-direction: column;
}

.div__register-form-header {
    display: flex;
    justify-content: space-between;
}

.div__register-formContainer a {
    color: #86c232;
}

.div__register-header {
    color: white;
}

.div__register-signin {
    display: flex;
    margin: 1rem 0rem;
    justify-content: flex-start;
}

.div__register-signin a {
    margin-left: 15px;
}

.div__register-formContainer-details {
    display: flex;
}

.div__register-formContainer-details .div__txtInput {
    width: 90%;
}

.div__txtInput input {
    height: 25px;
}

.div__register-formContainer-details div {
    flex: 1;
}

.div__register-formContainer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div__register-formContainer-details .lblInput,
.div__register-formContainer-address .lblInput,
.div__register-formContainer-userLanguage .lblInput {
    font-size: 14px;
}

.div__register-formContainer-address {
    display: flex;
    flex-direction: column;
}

.div__register-formContainer-addressLine {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
}

.div__register-formContainer-addressLine .div__txtInput {
    width: 45%;
    margin-right: 25px;
}

.div__register-formContainer-addressLocation .div__txtInput,
.div__register-formContainer-addressLocation .div__autoComplete-input {
    width: 33%;
    margin-right: 20px
}

.div__register-formContainer-addressLocation-state .MuiOutlinedInput-root {
    height: 42px;
}

.div__txtInput_label-icon .fieldIcon {
    color: #86c232;
    height: 20px;
    margin-top: 4px;
}

.div__register-formContainer-addressLocation {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.lblSection {
    font-size: clamp(1.25rem, 1.8vw, 2rem);
    margin: 4px 0px;
}

.div__uploadProfilePic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 160px;
}

.div__uploadProfilePic-image {
    height: 160px;
    width: 160px;
    border: 1px solid #585858;
    border-radius: 50%; 
}

.div-uploadPic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.div__register-formContainer-right .lblUpload {
    margin-top: 30px;
}

.div__register-formContainer-right-phoneNumber {
    display: flex;
    width: 90%;
    justify-content: space-around;
}

.div__register-formContainer-right-phoneNumber .div__countryCodeAutoComplete {
    flex: 0.3;
    margin-right: 10px;
}

.div__register-formContainer-right-phoneNumber .div__countryCodeAutoComplete .MuiAutocomplete-root {
    margin-top: 5px;
}

.div__register-formContainer-userLanguage {
    display: flex;
    justify-content: space-between;
}

.autoCompleteMotherTongue {
    padding: 0px;
    background-color: white;
    color: black;
    height: 37px;
    border-radius: 8px;
    align-self: flex-end;
    width: 93%;
}

.div__register-formContainer-userLanguage .div__txtAutocomplete_tags {
    flex: 1;
    width: 90%;
    margin-left: 30px;
}

.div__register-formContainer-userLanguage .div__autoComplete-input {
    flex: 1;
}

.div__register-formContainer-userLanguage .txtAutoCompleteTags  {
    position: relative;
    outline: none;
    margin: 0px;
    width: 95%;
}

.txtAutoCompleteTags > div.MuiInput-root {
    background-color: white;
    border-radius: 8px;
    padding: 0px 5px;
}

.txtAutoCompleteTags > label.MuiInputLabel-root {
    color: white;
    font-size: 18px;
    margin: -5px 5px;
}

.txtAutoCompleteTags > .MuiFormControl-root {
    margin-top: 7px;
}

#btnInput.div__register-formContainer-submit,
#btnInput.div__register-formContainer-submit:hover {
    width: 250px;
    height: 50px;
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    background-color: #86c232;
}