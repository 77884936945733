.div__ChatDetails-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 85%;
}

.div__ChatDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    text-align: center;
}

.ChatDetails__header,
.ChatDetails_Date,
.ChatDetails__SelectedOrder,
.ChatDetails__SelectedOrderID,
.ChatDetails__VendorDetails
{
    margin: 20px 0px;
}

.ChatDetails__header{
    color: white;
    font-size: clamp(1.5rem, 1.8vw,1.8rem);
}

.ChatDetails__Date,
.ChatDetails__SelectOrder__OrderItem,
.ChatDetails__SelectOrderID__ID,
.ChatDetails__VendorDetails__Name,
.ChatDetails__VendorDetails__Contact{
    margin: 5px 0px;
    font-size: clamp(1rem,1vw,1.5rem);
}

.ChatDetails__Date-header,
.ChatDetails__SelectedOrder-header,
.ChatDetails__SelectedOrderID-header,
.ChatDetails__VendorDetails-header{
    color: #adadad;
    font-size: clamp(1rem,1vw,1.5rem);
}

.div__ChatDetails-actions .div__ChatDetails-actions-closeChat {
  color: #86c232;
  width: 200px;
  border-radius: 18px;
  border: solid 2px #86c232;
}

.div__Chat-Container-isClosed {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: lightgray;
}