.file-upload__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px dashed #cbcbcb;
  border-radius: 22px;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin: 10px;
  background-color: transparent;
}

.file-upload__container .file-upload__icon {
    font-size: 60px;
}

.file-upload__text {
    font-size: 23px;
    margin: 10px;
}

.file-upload__accepted-files {
  display: flex;
  justify-content: space-around;
  width: 95%;
}

.file-upload__accepted-files .acceptedImage {
  width: 220px;
  height: 150px;
}