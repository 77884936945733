.MuiDialog-container .MuiDialog-paper {
    background-color: #212628;
    max-width: 75%;
    height: auto;
}

button.button_no, button.button_yes {
    width: 50%;
    font-size: clamp(1rem,1.5vw,2rem);
    text-transform: none;
}

.imgMemeTLogo {
    aspect-ratio: 101/116;
    width: 20%;
    align-self: center;
    margin-top: -40px;
}

.div__popup-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

button.button_yes, button.button_yes:hover {
    color: #74a730;
    border: 1px solid #74a730;
}

button.button_no, button.button_no:hover {
    background-color: #86c232;
    color: white;
}

.closeButton {
    align-self: flex-end;
}

.iconClose {
    color: white;
    font-size: 30px;
}

.MuiDialogContent-root {
    align-self: center;
}

.MuiDialogActions-root.div__popup-buttons {
    justify-content: space-around;
    width: 95%;
}

.MuiDialogActions-root.div__popup-buttons .MuiDialogActions-spacing{
    width: 90%;
}

@media (max-width : 845px) {
    
    .MuiDialog-container .MuiDialog-paper {
        width: 80%;
    }
}