.admin__vendorsList {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.admin__vendorsList-header,
.admin__vendorsList-header svg {
    font-size: clamp(1.5rem, 2vw, 2.5rem);
    vertical-align: middle;
    margin: 20px 0px
}

.admin__vendorsList__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
}

.admin__vendorsList__searchInput {
    width: 100%;
    height: 50px;
}

.admin__vendorsList__searchInput input {
    font-size: clamp(1.5rem,1vw,1.5rem);
    padding: 10px;
    height: 26px;
}

.admin__vendorsList__fromDate .MuiOutlinedInput-root.MuiInputBase-root,
.admin__vendorsList__toDate .MuiOutlinedInput-root.MuiInputBase-root {
    background-color: #212628;
    color: white;
    font-size: clamp(1rem,1vw,1.5rem);
    width: 100%;
    height: 45px;
}

.admin__vendorsList__filters .div_Filters {
    display: flex;
}

.div__dropdown-input .admin__vendorsList__status.MuiOutlinedInput-root.MuiInputBase-root,
.div__dropdown-input .admin__vendorsList__category.MuiOutlinedInput-root.MuiInputBase-root {
    background: #212628;
    color: white;
    width: 100%;
    height: 45px;
    font-size: clamp(1rem,1vw,1.5rem);
    text-align: left;
}

.admin__vendorsList__status .MuiSelect-icon.MuiSelect-iconOutlined,
.admin__vendorsList__category .MuiSelect-icon.MuiSelect-iconOutlined {
    color: white;
}

.admin__vendorsList__fromDate svg,
.admin__vendorsList__toDate svg {
    color: white;
}

.MuiList-root {
    background-color: #212628;
    color: white;
}

.MuiList-root li {
    font-size: clamp(1rem,1vw,1.5rem);
}

.MuiDrawer-root.admin__vendorsList__vendorDrawer--view .MuiDrawer-paper {
    height: 85%;
}

.MuiDrawer-root.admin__vendorsList__vendorDrawer--view .btnCloseDrawer svg {
    color: #707070;
}

.div__searchInput{
    display: flex;
    width: 40%;
}

.div__detail-item-header{
    height: auto !important;
}

.admin__vendorsList__filters .div_Filters .div__dateRangePicker-container{ 
    width: 30%;
   padding: 0px 10px;
   font-size: clamp(1rem,1vw,1.5rem);
}

.admin__vendorsList__filters .div_Filters .div__dropdown-input{
    width: 30%;
    padding: 0px 10px;
    font-size: clamp(1rem,1vw,1.5rem);
}

.admin__vendorsList .div__tableList-container th.headerColumn, .div__tableList-container tr.dataRow td, .div__tableList-container .noDataRow{
    font-size: clamp(1rem,1vw,1.5rem) !important;
}

.admin__vendorsList .MuiTablePagination-selectLabel, .admin__vendorsList .MuiTablePagination-selectIcon, .admin__vendorsList .MuiTablePagination-select.MuiSelect-select, .admin__vendorsList .MuiTablePagination-displayedRows, .admin__vendorsList .MuiTablePagination-actions .MuiSvgIcon-root{
    font-size: clamp(1rem,1vw,1.5rem) !important; 
}

@media (max-width:600px) {
    
    .MuiDrawer-root.admin__vendorsList__vendorDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
        padding: 15px;
    }

    .MuiDrawer-paper .btnCloseDrawer{
        margin-top: 20px;
    }
}