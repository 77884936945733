.div__carousel-container {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    margin: 0 auto;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.div__carousel-container::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.div__carousel-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
  
.div__carousel-items {
    display: flex;
    /* width: 100%; */
    margin: 10px 50px;
}