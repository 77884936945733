.div__userCard-Container{
    display: flex;
    align-items: center;
    border: solid 0.5px grey;
    justify-content: space-between;
    width: 100%;
}

.div__userCard-Container_Selected{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
    background: #212628;
}

.div__userCard-Details-top{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
}
.div__userCard-Details{
    width: 85%;
    margin: 15px;
}

.div__userCard-Details-bottom{
    margin: 10px 0px;
    color: grey;
}

.userLastText{
    color: rgb(206, 206, 206);
}

.div__userCard-Image{
    margin: 10px;
}