.myOrdersList__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}

.myOrdersList__filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.myOrdersList__filters .div__dateRangePicker-container {
  margin: auto;
  padding : 0px 10px 0px 0px;
  height: 55px;
}

.myOrdersList__header {
  display: flex;
  justify-content: space-between;
}

.myOrdersList__headerText {
  margin: 15px 0px;
  font-size: 44px;
}

#iconMemeSuggestion {
  font-size: 44px;
  margin-bottom: -5px;
}

.myOrdersList__overview {
  display: flex;
  margin: 10px 0px;
  justify-content: flex-start;
}

.myOrdersList__grid {
  flex: 0.65;
}

.myOrdersList__searchInput {
 width: 100%;
 font-size: 20px;
 margin: 20px 0px !important;
}


.myOrdersList__emptyList {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
}

.myOrdersList__imgEmptyList {
  width: 333px;
  height: 349px;
}

.myOrdersList__lblEmptyListMessage,
.myOrdersList__lblEmptyListSubMessage {
  margin: 20px 0px;
}

.myOrdersList__lblEmptyListMessage {
  font-size: 22px;
}

.myOrdersList__lblEmptyListSubMessage {
  font-size: 22px;
}

.myOrdersList__popupContent {
  text-align: center;
  padding: 10px;
}

.myOrdersList__popupContent-confirmQuestion {
  font-size: 25px;
}

.myOrdersList__popupContent-confirmMessage {
  font-size: 20px;
  margin: 25px;
  color: #a5a5a5;
}

.div__detail-item-actions div {
  font-size: 28px;
  cursor: pointer;
}

.MuiDrawer-root.myOrdersList__orderDrawer--view .MuiDrawer-paper {
  background-color: white;
}

.MuiDrawer-root.myOrdersList__orderDrawer--view .MuiDrawer-paper {
  height: 85%;
  padding: 2% auto;
}

.MuiDrawer-root.myOrdersList__orderDrawer--view .btnCloseDrawer svg {
  color: #707070;
  margin-top: 35px;
}
