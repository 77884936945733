.ChatFooter__Container{
    display: flex;
    height: auto;
    background: #d3d3d3;
    padding: 15px 20px;
    width: 350px;
    border-radius: 0px 0px 10px 10px;
    align-items: center;
    justify-content: space-between;
}

.ChatFooter__Container .MuiTextField-root{
    width: 95%;
}

.ChatFooter__Container .icon__SendButton{
    font-size: clamp(1.8rem,2.5vw,3rem);;
    color: darkgrey;
    cursor: pointer;
    margin-top: 2%;
}

.ChatFooter__Container .div__txtInput .MuiFormControl-root .MuiOutlinedInput-root.MuiInputBase-root {
    height: 55px;
}

.ChatFooter__Container .div__txtInput .errorMessage{
    display: none;
}