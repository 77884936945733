.vendorDashboard__gridContainer--vendorTransactions {
    margin-top: 50px;
}

.vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-header {
    background-color: #212628;
    font-size: clamp(1rem, 1.2vw, 1.8rem);
    padding: 0px 20px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 20px 12px;
    border-bottom: 1px solid #707070;
    justify-content: space-between;
}

.vendorDashboard__gridContainer--vendorTransactions-header-viewAllTickets {
    color: #b4ff4f;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    
}

.div__detail-item.vendorDashboard__gridContainer--vendorTransactions {
    height: 100%;
    margin: 0px;
}

.vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-icon {
    vertical-align: middle;
    width: 30px;
    height: 30px;
}

.vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #212628;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    padding: 0px;
}

.vendorDashboard__gridContainer--vendorTransactions-content .div__tableList-container {
    height: 650px;
    overflow-y: auto;
    border-radius: 8px;
}

.vendorDashboard__gridContainer--vendorTransactions-content .div__tableList-container::-webkit-scrollbar {
    display: none;
}
  
.vendorDashboard__gridContainer--vendorTransactions-content .div__tableList-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vendorDashboard__gridContainer--vendorTransactions-fromDate .MuiOutlinedInput-root.MuiInputBase-root,
.vendorDashboard__gridContainer--vendorTransactions-toDate .MuiOutlinedInput-root.MuiInputBase-root {
    background-color: #212628;
    color: white;
    width: 100%;
    height: 40px;
    border: 1px solid #707070;
    padding: 10px;
}

.vendorDashboard__gridContainer--vendorTransactions-fromDate svg,
.vendorDashboard__gridContainer--vendorTransactions-toDate svg {
    color: white;
}

.vendorDashboard__gridContainer--vendorTransactions-content .listTable {
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.vendorDashboard__gridContainer--vendorTransactions-content .listIcon--increment,
.vendorDashboard__gridContainer--vendorTransactions-content .listIcon--decrement {
    font-size: 60px;
}

.vendorDashboard__gridContainer--vendorTransactions-content .listIcon--increment {
    color: #b4ff4f;
}

.vendorDashboard__gridContainer--vendorTransactions-content .listIcon--decrement {
    color: #d40000;
}

.vendorDashboard__gridContainer--vendorTransactions-fromDate,
.vendorDashboard__gridContainer--vendorTransactions-toDate{
    width: 100%;
}

.div__dateRangePicker-container{
    width: 25%;
    padding: 0px 10px;
}

.vendorDashboard__gridContainer--vendorTransactions-searchFilter{
    width: 30%;
    height: 35px;
    padding: 0px 10px;
}

.vendorDashboard__gridContainer--vendorTransactions-searchFilter .MuiOutlinedInput-input{
    padding: 7px 5px;
}