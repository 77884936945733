.div__footer-container {
  background-color: #212628;
  padding: 50px 20px;
  margin-top: 100px;
}

.div-footer-container p {
  color: #cbcbcb;
}

.div__footer-bottomContent,
.div__footer-pagesList {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem,1.5vw,1.5rem);
}

.div__footer-pagesList {
  flex-direction: column;
  flex: 0.2;
  justify-content: flex-start;
  align-items: baseline;
}

.div__footer-languagesList {
  flex: 0.35;
}

.div__footer-languagesList-container-languageText {
  text-decoration: none;
  color: white;
  margin: 8px;
  font-size: clamp(0.8rem,1.5vw,1.5rem);
  line-height: 40px;
}

.div__footer-pagesList-items .pageLink{
  font-size: clamp(0.8rem,1.5vw,1.5rem);
}
.lblHeader {
  color: #86c232;
  margin: 0px;
  font-size: clamp(0.95rem,1.5vw,1.5rem);
}

.lblLanguagesList {
  margin: 0px 8px;
  font-size: clamp(1rem,1.5vw,1.5rem);
}

.div__footer-contactUs {
  flex: 0.25;
}

.div__footer-bottomContent {
  margin-top: 15px;
}

.div__footer-bottomContent p {
  margin: 5px 20px;
}

.div__footer-topContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile .lblPagesList {
  margin: 0px 10px;
}

.pageLink {
  color: white;
  text-decoration: none;
  margin: 12px 0px;
}

.div__footer-container .div__footer-languagesList-container {
  margin-top: 30px;
}

.div__footer-contactUs-socialMedia,
.div__footer-contactUs-socialMedia-container,
.div__footer-pagesList-items {
  display: flex;
}

.div__footer-contactUs-socialMedia-container,
.div__footer-pagesList-items {
  flex-direction: column;
}

.div__footer-pagesList-items {
  margin-top: 22px;
}

.imgLogoMemeT {
  aspect-ratio: 1/1;
  width: 35%;
}

.div__footer-contactUs .lblHeader {
  width: 100%;
  margin: 0px 10px;
}

.lnkSocialMedia {
  height: 50px;
  width: 50px;
  margin: 20px;
  padding: 10px;
}

.div__footer-contactUs-socialMedia-links {
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
  width: 100%;
  align-self: center;
}

.tblContactUs {
  margin-top: 10px;
}

.tblContactUs td {
  color: white;
  padding: 5px;
  font-size: clamp(0.8rem,1.5vw,1.5rem);
}

.div__footer-contactUs-details {
  margin: 10px;
}

.div__footer-contactUs-socialMedia-links .lnkSocialMedia--icon {
  font-size: 32px;
  color:#cbcbcb;
  cursor: pointer;
}

.div__footer-contactUs-socialMedia-links .lnkSocialMedia--icon:hover {
  color:#86c232;
}