.myTickets__ticketCard {
  width: 98.5%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.27);
  max-width: 1337px;
}

.myTickets__ticketCard .myTickets__ticketCard-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.myTickets__ticketCard-content-image {
  width: 190px;
  height: 155px;
  border-radius: 8px;
}

.myTickets__ticketCard-content {
  display: flex;
  padding: 10px 20px;
}

.myTickets__ticketCard-content-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
  color: white;
}

.myTickets__ticketCard-content-details-titleDescription,
.myTickets__ticketCard-content-details-replyDescription {
  font-size: clamp(1.5rem, 1.8vh, 2rem);
}

.myTickets__ticketCard-content-details-titleLabel,
.myTickets__ticketCard-content-details-replyLabel {
  font-size: clamp(1rem, 1.5vh,2rem);
  color: #b7b7b7;
}

.myTickets__ticketCard-content-details-descHeader {
  font-size: clamp(1rem, 1.5vh, 2rem);
  color: #b7b7b7;
}

.myTickets__ticketCard-content-details-replyPlaceholder {
  color: #b7b7b7;
  font-size:  clamp(1rem, 1.5vh, 2rem);
}