.chatQuestionCard-container {
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 10px;
}

.chatQuestionCard-container-header {
    padding: 5px;
    background-color: #707070;
    color: white;
    text-align: center;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    border-radius: 10px 10px 0px 0px;
}

.chatQuestionCard-container-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chat__Container .chat__help--category,
.chat__Container .chat__help--subCategory {
    padding: 10px;
    width: 91%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid grey;
    border-radius: 0px 0px 10px 10px;
}

.chat__Container .chat__help--category:last-child,
.chat__Container .chat__help--subCategory:last-child {
    border-bottom: none;
}

.chat__help-container {
    border-radius: 8px;
    border: 1px solid #a0a0a0;
    margin-top: 10px;
}