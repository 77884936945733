.vendorAgreement__form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vendorAgreement__form--top {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin: 20px 0px;
}

.vendorAgreement__form--top--left {
    font-size: clamp(1rem, 2vw, 2.1rem);
    font-weight: bold;
}

.vendorAgreement__form--top--right {
    width: 20%;
    aspect-ratio: 312/187;
}

.vendorAgreement__form-termsAndConditions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 90%;
    margin: 44px 0px;
}

.vendorAgreement__form-termsAndConditions-statement {
    font-size: clamp(1.1rem, 1.6vw, 2rem);
    margin: 25px 0px;
}

#btnInput.vendorAgreement__form-agreeTerms,
.vendorAgreement__form .vendorAgreement__form-declineTerms {
    width: 365px;
    height: 57px;
    font-size: 22px;
    text-transform: uppercase;
}

.vendorAgreement__form .vendorAgreement__form-declineTerms {
    color: #86c232;
    font-size: 20px;
    text-transform: uppercase;
    border: 1px solid #86c232;
}

.vendorAgreement__form-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vendorAgreement__form-success-image {
    width: 40%;
    align-self: center;
    aspect-ratio: 57/68;
}

.vendorAgreement__form-success-message {
    font-size: clamp(1.3rem, 2vw, 2.5rem);
    width: 600px;
    margin-top: 30px;
    text-align: center;
}

.vendorAgreement__form-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
}