.vendorOnboarding__basicDetails-formContainer {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    padding: 0px 100px;
}

.vendorOnboarding__basicDetails-formContainer-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vendorOnboarding__basicDetails-formContainer-image .lblUpload {
    width: 50%;
}

.vendorOnboarding__basicDetails-formContainer-image--left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.vendorOnboarding__basicDetails-formContainer-image--left-header {
    font-size: clamp(1.3rem,1.5vw,2rem);
    align-self: center;
}

.vendorOnboarding__basicDetails-formContainer-image--right {
    width: 41%;
    aspect-ratio: 257/168;
    align-self: center;
}

.vendorOnboarding__basicDetails-formContainer-section {
    font-size: clamp(1.5rem,1.8vw,2rem);
    margin: 15px 0px;
}

.vendorOnboarding__basicDetails-formContainer-details,
.vendorOnboarding__basicDetails-formContainer-companyDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vendorOnboarding__basicDetails-formContainer-details--left,
.vendorOnboarding__basicDetails-formContainer-details--right,
.vendorOnboarding__basicDetails-formContainer-companyDetails--left,
.vendorOnboarding__basicDetails-formContainer-companyDetails--right {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.vendorOnboarding__basicDetails-formContainer-details--right {
    justify-content: end;
}

.vendorOnboarding__basicDetails-formContainer-details--left .div__txtInput,
.vendorOnboarding__basicDetails-formContainer-details--right .div__txtInput,
.vendorOnboarding__basicDetails-formContainer-companyDetails--left .div__txtInput,
.vendorOnboarding__basicDetails-formContainer-companyDetails--right .div__txtInput {
    width: 95%;
} 

.vendorOnboarding__basicDetails-formContainer-address {
    display: flex;
    flex-direction: column;
}

.vendorOnboarding__basicDetails-formContainer-addressLine {
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 98%;
}

.vendorOnboarding__basicDetails-formContainer-addressLine .div__txtInput {
    width: 49%;
}

.vendorOnboarding__basicDetails-formContainer-addressLocation .div__txtInput,
.vendorOnboarding__basicDetails-formContainer-addressLocation .div__autoComplete-input {
    width: 31%;
}

.vendorOnboarding__basicDetails-formContainer-addressLocation-state .MuiOutlinedInput-root {
    height: 43px;
}

.vendorOnboarding__basicDetails-formContainer-addressLocation {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 98%;
}

.vendorOnboarding__basicDetails-formContainer-companyDetails--right-entityType {
    height: 38px;
    width: 95%;
}

.vendorOnboarding__basicDetails-formContainer-heardAboutUsText {
    width: 98%;
}

.vendorOnboarding__basicDetails-formContainer-heardAboutUs .div__dropdown-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 0px;
}

.vendorOnboarding__basicDetails-formContainer-heardAboutUs .div__dropdown-input .MuiOutlinedInput-root {
    height: 40px;
}

.vendorOnboarding__basicDetails-formContainer-heardAboutUs .div__dropdown-input .lblInput {
    margin: 0px;
}

.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget .vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-label,
.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget .vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-label.Mui-focused {
    font-size: clamp(1.3rem,1.5vw,2rem);
    color: white;
}

.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-option .MuiRadio-root,
.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-option .MuiRadio-root.Mui-checked,
.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-option .MuiFormControlLabel-label,
.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-option .MuiFormControlLabel-label.Mui-checked {
    color: white;
    font-size: clamp(1rem, 1vw, 2rem);
}

.vendorOnboarding__basicDetails-formContainer-monthlySalesTarget .MuiFormGroup-root {
    display: -webkit-box;
}

#btnInput.vendorOnboarding__basicDetails-formContainer-submit {
    width: 365px;
    font-size: 22px;
    height: 60px;
    text-transform: uppercase;
    margin-top: 42px;
}

.vendorOnboarding__basicDetails-formContainer-companyDetails--right .div__dropdown-input{
    width: 100%;
    padding: 0px;
}

.vendorOnboarding__basicDetails-formContainer-companyDetails--right-entityType{
    width: 96%;
}

.vendorOnboarding__basicDetails-formContainer-image--left .uploadProfilePic{
    aspect-ratio: 1;
    width: 40%;
    height: 40%;
}
