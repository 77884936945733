.div__OTPAuth-container {
  background: #585858;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.div__OTPAuth-formContainer {
  display: flex;
  width: 25%;
  height: 747px;
  margin: 75px;
  padding: 1em 1.5em;
  border-radius: 21px;
  border: solid 2px #585858;
  background-color: #222629;
  flex-direction: column;
  font-family: Helvetica;
  align-self: flex-end;
}

.div__OTPAuth-form {
  display: flex;
  flex-direction: column;
  height: 530px;
}

.div__logo {
  display: flex;
  justify-content: center;
  object-fit: contain;
  margin: 5px;
}

.imgLogo {
  width: 162px;
  height: 143px;
}

.div__txtInput input {
  border-radius: 10px;
}

.div__txtInput_label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.div__OTPAuth-form .lblInput {
  font-size: clamp(1rem, 1.4vw, 1.5rem);
  color: white;
  margin-bottom: 5px;
}

.div__phoneNoWithCC {
  display: flex;
  flex-wrap: wrap;
}

.div__OTPAuth-formContainer-OTPMessage {
  display: flex;
  flex-direction: column;
}

div.MuiOutlinedInput-root {
  padding: 1px;
}

.div__OTPAuth-formContainer-verifyPhone .div__txtInput {
  flex: 1;
}

.lblOTPMessage,
.lblPhoneNumber {
  margin: 15px 0px;
  display: flex;
  color: white;
}

.lblChangePhoneNumber {
  color: #86c232;
  cursor: pointer;
  margin-left: 10px;
}

.div__OTPAuth-formContainer-verifyPhone {
  padding: 10px 0px;
}

.div__OTPAuth-formContainer-changePassword {
  padding: 20px 30px;
}

#btnInput.btnActionOTP {
  width: 80%;
  font-size: clamp(1rem, 1.5vw, 2rem);
}

.div__resendOTP {
  display: flex;
  justify-content: center;
}

.lnkResendOTP {
  color: #86c232;
  cursor: pointer;
  margin: 0px 10px;
}

.lblForgotPassword {
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-stretch: normal;
  letter-spacing: normal;
  align-self: flex-start;
  color: #fff;
}

.dividerLine {
  height: 0px;
  border: 0.5px solid white;
  width: 100px;
}

.css-1tmlwvc-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px;
}

.div__countryCodeAutoComplete{
  width: 40%;
}

.div__countryCodeAutoComplete .MuiAutocomplete-root{
    width: 100%;
}

.div__OTPAuth-formContainer .div__txtInput{
  width: 100%;
  padding: 0px 10px;
}

.lblOTPTimeout {
  color: #fff;
}