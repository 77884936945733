.myTickets__container {
  padding: 4% 15%;
}

.myTickets {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-direction: column;
}

.myTickets-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.myTickets__header {
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.myTickets__header-text {
  margin: 15px 0px;
  font-size: clamp(1.3rem, 4vw, 2rem);
}

#iconTicket {
  font-size: 30px;
  margin-bottom: -5px;
}

#btnInput.ticket_detail-addTicket {
  width: 224px;
  height: 52px;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.MuiDrawer-root.myTickets__ticketDrawer--add .MuiDrawer-paper {
  background-color: white;
}

.MuiDrawer-root.myTickets__ticketDrawer--add .MuiDrawer-paper {
  height: 80%;
}

.MuiDrawer-root.myTickets__ticketDrawer--add .btnCloseDrawer svg {
  color: black;
}

.myTickets__filters .div__dropdown-input{
  width: 25%;
}
