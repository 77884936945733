.bulkUpload__container {
    height: 75vh;
    padding: 20px 100px;
}

.bulkUpload__container .bulkUpload__fileInput {
    height: 200px;
    width: 50%;
    margin: auto;
}

.bulkUpload__header,
.bulkUpload__header .bulkUpload__header-icon {
    font-size: 36px;
    vertical-align: middle;
}

.bulkUpload__header--text {
    margin: 2% 3%;
    font-size: 23px;
}

.bulkUpload__container .file-upload__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.file-upload__buttons #btnInput.file-upload__buttons-accept,
.file-upload__buttons .file-upload__buttons-cancel {
    width: 240px;
    height: 52px;
    font-size: 24px;
    text-transform: capitalize;
    margin: 10px;
}

.file-upload__buttons .file-upload__buttons-cancel {
    border-radius: 7px;
    border: solid 2px #86c232;
    color: #86c232;
}