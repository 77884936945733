.vendorWalletDashboard {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.vendorWalletDashboard__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 15px;
    width: 99%;
}

.vendorWalletDashboard__header--left,
.vendorWalletDashboard__header--left-icon {
    color: #f5f5f5;
    font-size:clamp(2rem, 2vw, 2.5rem);
    vertical-align: middle;
    align-items: baseline;
}

.vendorWalletDashboard__header--left-walletUpdateTime {
    font-size: clamp(0.8rem, 1.5vw, 1rem);
    color: #cbcbcb;
    margin-left: 5px;
}

.vendorWalletDashboard__header .vendorWalletDashboard__header--left-icon {
    color: #cbcbcb;
    font-size: 40px;
    vertical-align: middle;
}

.vendorWalletDashboard__header--right{
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.vendorWalletDashboard__header--right-action {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vendorWalletDashboard__header--left-header {
    font-size: clamp(1.5rem, 2vw, 2rem);
}

.vendorWalletDashboard__header .vendorWalletDashboard__header--right-addBankAccount-button,
.vendorWalletDashboard__header .vendorWalletDashboard__header--right-addBankAccount-button:hover {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #74a730;
    font-size:clamp(1rem,1vw,1.5rem);
    color: white;
}

.vendorWalletDashboard__header--right-addBankAccount-text {
    color: #f5f5f5;
    font-size: clamp(0.75rem, 1.5vw, 1.2rem);
    margin: 8px;
    text-align: center;
}

.vendorWalletDashboard__gridContainer {
    width: 100%;
}

.walletBalance__card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px;
    justify-content: space-around;
    background-color: #212628;
}

.div__detail-item-content.walletBalance__card-content {
    padding: 0px 20px;
    height: 100%;
    width: -webkit-fill-available;
}

.div__detail-item.walletBalance__card {
    height: 290px;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
}

.walletBalance__card--left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    flex: 1.5;
}

.walletBalance__card--left-header,
.walletBalance__card--left-content,
.walletBalance__card--left-subContent {
    color: #cbcbcb;
}

.walletBalance__card--left-header {
    font-size:  clamp(1.2rem, 1.5vw, 2rem);
}

.walletBalance__card--left-subHeader {
    display: inline-flex;
    font-size: clamp(0.8rem, 1vw, 1rem);
}

.walletBalance__card--left-subContent {
    font-size: 16px;
}

.walletBalance__card--left-content {
    font-size: clamp(1.7rem,2vw,4rem);
    margin: 10px 0px;
}

.walletBalance__card--right {
    font-size: 33px;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.walletBalance__card--right .walletBalance__card--right-increment,
.walletBalance__card--right .walletBalance__card--right-decrement {
    font-size: 33px;
    vertical-align: middle;
}

.walletBalance__card--right .walletBalance__card--right-increment {
    color: #74a730;
}

.walletBalance__card--right .walletBalance__card--right-decrement {
    color: #d40000;
}

.walletBalance__card .walletBalance__card--right-accountBalance {
    font-size: clamp(4rem,7vw,8rem);
    color: #cbcbcb
}

.vendorWalletDashboard .vendorWalletDashboard__gridContainer{
    width: 100%;
    margin: 0px auto;
}

.vendorWalletDashboard__gridContainer-walletBalance, .vendorWalletDashboard__gridContainer-bankAccountsList{
    width: 40%;
}

.vendorWalletDashboard__gridContainer-monthlySummary, .vendorWalletDashboard__gridContainer-transactionsList{
    width: 60%;
}

.vendorDashboard__gridContainer--vendorTransactions-header .div__Filters{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vendorDashboard__gridContainer--vendorTransactions-header-name{
    width: 25%;
}

.MuiDialog-container .MuiDialogContent-root {
    padding: 0px 20px;
}