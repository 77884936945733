/* .bankAccount__form-header--title,
.bankAccount__form-header--subTitle {
    color: #585858;
} */

.bankAccount__form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bankAccount__form-header--title {
    font-size: clamp(1.5rem, 2vw, 3rem);
    margin-bottom: 11px;
}

.bankAccount__form-header--subTitle {
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin-bottom: 22px;
}