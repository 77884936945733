.div__aboutUs-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.aboutUs_HeaderText{
  font-size: clamp(1.3rem, 4.5vw, 4rem);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #f6f6f6;
  margin-top: 25px;
  text-align: center;
}

.div__aboutUs-introVideo,
.div__aboutUs-introVideoFrame {
    display: flex;
    aspect-ratio: 1920/1080;
    width: clamp(200px,90%,1020px);
    margin: auto;
}

.div__aboutUs-introVideo .MuiSvgIcon-root{
    align-self: center;
    margin-left: 47%;
    font-size: clamp(2rem,5vw,5.5rem);
}

.div__aboutUs-languages, .div__aboutUs-USP, .div__aboutUs-Designer{
    width:100%;
}

.div__aboutUs-languages, .div__aboutUs-Designer{
    margin-top: 20px;
}

.div__aboutUs-USP{
    margin-top: 20px;
}

.div__aboutUs-languages-description-text, .div__aboutUs-USP-description-text, .div__aboutUs-Designer-description-text{
    font-size: clamp(0.8rem, 1.2vw,2rem);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.div__aboutUs-languages-description-header, .div__aboutUs-USP-description-header, .div__aboutUs-Designer-description-header {
    font-size: clamp(0.8rem, 1.2vw,2rem);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: rgb(171, 170, 170);
}
.div__aboutUs-USP-description-text{
    text-align: right;
}

.div__aboutUs-USP-description{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 0% 2%;
}

.div__aboutUs-languages-languageGraph, .div__aboutUs-USP-imgPlatformUSP,.div__aboutUs-Designer-imgDesigner{
    display: flex;
    justify-content: center;
}

.div__aboutUs-languages-languageGraph {
    width: 944px;
    height: 415px;
    margin: 30px auto;
    object-fit: cover;
}

.div__aboutUs-USP-imgPlatformUSP {
    width: 967px;
    height: 393px;
    margin: 30px auto;
    object-fit: cover; 
}

.div__aboutUs-Designer-imgDesigner {
    width: 419px;
    height: 408px;
    margin: 50px auto;
    object-fit: cover;
}

.div__aboutUs-Designer-description, .div__aboutUs-languages-description, .div__aboutUs-USP-description{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0% 2%;
}

.div__aboutUs-USP-description-header, .div__aboutUs-USP-description-text{
    display: flex;
    justify-content: flex-end;
    padding: 10px 50px;
    width: 70%;
}

.aboutUs-USP-vendor--onboarding {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 50px auto;
    border-width: 10px;
    border-radius: 25px;
    border-style: solid;
    border-color: #cbcbcb;
}

.aboutUs-USP-vendor--onboarding-text,
.aboutUs-USP-vendor--onboarding-link {
    margin: auto;
    padding: 20px 0px;
    font-size: clamp(1rem, 1.5vw, 2rem);
    font-weight: bold;
}

.aboutUs-USP-vendor--onboarding-link {
    background-color: transparent;
    color: #86c232;
}

#btnInput.aboutUs-USP-vendor--onboarding-button {
    height: 50px;
    font-size: 22px;
}

.div__aboutUs-Designer-description-header, .div__aboutUs-Designer-description-text,
.div__aboutUs-languages-description-header, .div__aboutUs-languages-description-text{
    display: flex;
    justify-content: flex-start;
    padding: 10px 50px;
    width: 70%;
}
