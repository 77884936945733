html,
body {
  height: 100%;
  background-color: #585858;
}

body {
  margin: 0;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 a,
p {
  color: white;
  font-family: Helvetica;
  padding: 0px;
  margin: 0px;
}

.lblInput {
  font-family: Helvetica;
  font-size: 20px;
  color: white;
  margin: 5px;
}

#btnInput,
#btnInput:hover {
  position: relative;
  width: auto;
  align-self: center;
  margin: 5px;
  background-color: #86c232;
  text-transform: none;
  border-color: #86c232;
  color: white;
  font-size: 18px;
  z-index: 1;
}

#btnInput::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  z-index: -1;
  transition: transform 200ms ease-in-out;
}

#btnInput::after {
  transform: scaleX(0);
  transform-origin: right;
}
#btnInput:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.MuiCircularProgress-root.loadingProgress {
  align-self: center;
  color: white;
}

#root .MuiSnackbar-root,
#root .MuiSnackbarContent-root,
.MuiDrawer-root .MuiSnackbar-root,
.MuiDrawer-root .MuiSnackbarContent-root {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

#root .MuiSnackbar-root,
.MuiDrawer-root .MuiSnackbar-root {
  top: 0px;
  box-shadow: none;
}

#root .MuiSnackbarContent-root,
.MuiDrawer-root.MuiPaper-root .MuiSnackbar-root,
.MuiDrawer-root.MuiPaper-root .MuiSnackbarContent-root {
  min-width: 652px;
  justify-content: center;
  background-color: #212628;
}
