.div__userList{
    height: 90.5vh;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #707070;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
}

.searchUserChat {
    padding: 3px 5px;
}

.div__userList::-webkit-scrollbar{
    display:none;
}

.div__userList{
    -ms-overflow-style: none;
    scrollbar-width: none;
}