.OrderPrompt__Container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.OrderPromptCard{
    display: flex;
    width: 100%;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.16);
    height: 60px;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

.OrderItemImage{
    width: 60px;
    height: 60px;
    border-radius: 5px;
}

.OrderPromptCard__text .OrderName,
.OrderPromptCard__text .OrderID{
    color: #808080;
    font-size: 12px;
}

.SendCard__text .OrderPrompt__Container {
    background-color: white;
}

.SendCard__text .OrderName,
.SendCard__text .OrderPromptCard__text .OrderID{
    color: black;
    font-size: 12px;
}