.Admindiv__Chat-Container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
}

.div__Chat-Container{
    width: 50%;
    height: 100%;
}

.div__Chat-Container .chat__help--category,
.div__Chat-Container .chat__help--subCategory {
    padding: 10px;
    width: 97%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid grey;
}

.div__Chat-Container .chat__help--category:last-child,
.div__Chat-Container .chat__help--subCategory:last-child {
    border-bottom: none;
}

.chat__help-container {
    border-radius: 8px;
    border: 1px solid #a0a0a0;
    margin-top: 10px;
}

.div__Chat-Container .AdminchatHeader__Container {
    height: auto;
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 0px;
    background: none;
    border: solid 1px grey;
    align-items: center;
}

.div__Chat-Container .chatList__Container{
    display: flex;
    padding: 0px;
    width: 100%;
    height: 73%;
    justify-content: center;
}

.div__Chat-Container .ChatFooter__Container{
    width: 100%;
    padding: 0px;
}

.Admindiv__Chat-Container .ReceiveCard
,.Admindiv__Chat-Container .sendCard{
    margin: 15px;
}


.Admindiv__Chat-Container .OrderPromptCard{
    padding: 0px;
    height: auto;
    margin:15px;
}

.Admindiv__Chat-Container .OrderItemImage,
.Admindiv__Chat-Container .OrderPromptCard__text{
    margin: 15px;
}

.Admindiv__Chat-Container .AdminChatHeader__Container{
    margin: 15px;
}

.div__Chat-Container .ChatFooter__Container .div__txtInput{
    margin: 15px;
}