.chat__Container {
    box-shadow: 3px 35px 0 rgba(0, 0, 0, 0.33);
    border-radius: 10px;
}

.chat__Container .chat__help--category,
.chat__Container .chat__help--subCategory {
    padding: 10px;
    width: 91%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid grey;
    border-radius: 0px 0px 10px 10px;
}

.chat__Container .chat__help--category:last-child,
.chat__Container .chat__help--subCategory:last-child {
    border-bottom: none;
}

.chat__help-container {
    border-radius: 8px;
    border: 1px solid #a0a0a0;
    margin-top: 10px;
}